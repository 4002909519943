import React from 'react'
import HeadingAndImage from '../components/HeadingAndImage'
import ImageBubble from '../elements/ImageBubble'
import LetterBubble from '../elements/LetterBubble'
import Section from '../elements/Section'
import TextBubble from '../elements/TextBubble'
import asset from '../images/AssetOne.png'
import welcomeImage from '../images/Parent_Illustrations-01.svg'
import whyImage1 from '../images/Parent_Illustrations-02.svg'
import whyImage2 from '../images/Parent_Illustrations-03.svg'

import H from '../images/Parent_Illustrations-04.svg'
import E from '../images/Parent_Illustrations-05.svg'
import A1 from '../images/Parent_Illustrations-06.svg'
import R from '../images/Parent_Illustrations-07.svg'
import T from '../images/Parent_Illustrations-08.svg'
import S1 from '../images/Parent_Illustrations-09.svg'
import S2 from '../images/Parent_Illustrations-10.svg'
import M from '../images/Parent_Illustrations-11.svg'
import A2 from '../images/Parent_Illustrations-12.svg'
import P from '../images/Parent_Illustrations-13.svg'

import whoImage from '../images/Parent_Illustrations-14.svg'
import usingImage1 from '../images/Parent_Illustrations-15.svg'
import usingImage2 from '../images/Parent_Illustrations-16.svg'
import result from '../images/Parent_Illustrations-17.svg'

import CIHR from '../images/Youth_Illustrations-CIHR Logo.svg'
import UBC from '../images/Youth_Illustrations-ubc.png'
import BC from '../images/Youth_Illustrations-BC.png'
import anti from '../images/Youth_Illustrations-AG Design.png'
import echo from '../images/Youth_Illustrations-Echo.png'
import arche from '../images/Youth_Illustrations-Arche.png'

const Welcome = () => {
    const data = [
        {id:"H", letter:"H",image:`${H}`, text1:"Home:", text2:"Assesses your child's home life and home resources"},
        {id:"E", letter:"E",image:`${E}`, text1:"Education & Activities:", text2:"Assesses your child's school, work, and hobbies"},
        {id:"A1", letter:"A",image:`${A1}`, text1:"Alcohol & Drugs:", text2:"Assesses your child's alcohol and drug use"},
        {id:"R", letter:"R",image:`${R}`, text1:"Relationships & Bullying:", text2:"Assesses your child's friendships and support"},
        {id:"T", letter:"T",image:`${T}`, text1:"Thoughts and Anxiety:", text2:"Assesses your child's feelings of stress"},
        {id:"S1", letter:"S",image:`${S1}`, text1:"Safety:", text2:"Assesses your child's physical safety and the safety of those around them"},
        {id:"S2", letter:"S",image:`${S2}`, text1:"Sexual Health:", text2:"Assesses your child's sexual health practices and knowledge"},
        {id:"M", letter:"M",image:`${M}`, text1:"Mood:", text2:"Assesses your child's mood and behaviours"},
        {id:"A2", letter:"A",image:`${A2}`, text1:"Abuse:", text2:"Assesses present and past experiences of abuse your child may have had"},
        {id:"P", letter:"P",image:`${P}`, text1:"Professionals and Resources:", text2:"Assesses the resources your child currently uses and ones they may be interested in"}
    ]

    const dataInput = data?.map(({letter,image,text1,text2,id})=>{
        return(
            <LetterBubble 
                id={id}
                letter={letter}
                Children={
                    <ImageBubble
                        image={image}
                        pinktext={text1}
                        desc={text2}
                    />
                }   
            />
        )
    })
    return (
        <>
            <Section passID="welcome">
                <div className="one">
                    <img alt='image' src={asset}/>
                </div>
                <HeadingAndImage
                    heading='What is MyHEARTSMAP?'
                    image={welcomeImage}
                    imgwidth='55%'
                />
            </Section>
            <Section passID="why">
                <HeadingAndImage
                heading='Why was MyHEARTSMAP created?'
                image={whyImage1}
                imgwidth='90%'
                >
                    <TextBubble
                        textInput='When your child experiences difficult mental health symptoms, long wait times at the emergency department can be frustrating.'
                        backColor='#fff'
                    />
                </HeadingAndImage>
                <HeadingAndImage
                // heading='Why was MyHEARTSMAP created?'
                image={whyImage2}
                imgwidth='60%'
                >
                    <TextBubble
                        textInput='	MyHEARTSMAP was created to assess your child’s mental health resource needs, and connect them with the appropriate supports.'
                        backColor='#fff'
                    />
                </HeadingAndImage>
            </Section>
            <Section passID="what">
                <HeadingAndImage heading='What does MyHEARTSMAP assess?'>
                    <p style={{marginTop:'-20px',marginBottom:'20px'}}>MyHEARTSMAP assesses the following domains: </p>
                </HeadingAndImage>
                {dataInput}
            </Section>
            <Section passID="who">
                <HeadingAndImage
                heading='Can I fill this out for my child?'
                image={whoImage}
                imgwidth='90%'
                >
                    <TextBubble
                        textInput='MyHEARTSMAP highly reliable when filled out by youth 10-17 on their own or by parents on behalf of their child aged 6-17.'
                        backColor='#FFF'
                    />
                </HeadingAndImage>
            </Section>
            <Section passID="using">
                <HeadingAndImage
                    heading='Using the tool'
                />
                <div className="side-by-side">
                    <img src={usingImage1} alt='usingImage1'/>
                    <TextBubble
                        textInput='MyHEARTSMAP is available online and can be used on any device with internet service.'
                        backColor='#e1e1e1'
                    />
                </div>
                <HeadingAndImage
                image={usingImage2}
                imgwidth='90%'
                >
                    <TextBubble
                        textInput='You can fill out the questionnaire at home, in the emergency department, or anywhere with Internet access.'
                        backColor='#e1e1e1'
                    />
                </HeadingAndImage>
            </Section>
            <Section passID="results">
                <HeadingAndImage
                heading='What should I do with the results?'
                image={result}
                imgwidth='50%'
                >
                    <TextBubble
                        textInput='The results from the MyHEARTSMAP tool can be shared 
                        with healthcare providers or reserved for personal use'
                        backColor='#FFF'
                    />
                </HeadingAndImage>
            </Section>
            <Section passID="credits">
                <HeadingAndImage
                    heading='Find out if MyHEARTSMAP is right for your child.'
                />
                <div className="footer">
                    <p className="footerbanner">© 2015 BY DR. QUYNH DOAN AND DR. TYLER R. BLACK</p>
                    <div className="footer2">
                        <div className="footerSection">
                            <p className="footerText">THE MYHEARTSMAP TOOL WAS SUPPORTED BY THE CANADIAN INSTITUTES OF HEALTH RESEARCH (CIHR) PROJECT GRANT PROGRAM.</p>
                            <div className="footerImgArea" id="CIHR">
                                <a href='https://cihr-irsc.gc.ca/e/193.html' target='_blank'><img alt='image' src={CIHR}/></a>
                            </div>
                        </div>
                        <div className="footerSection">
                            <p className="footerText" style={{padding:'5px 60px'}}>DR. DOAN IS SUPPORTED BY THE UNIVERSITY OF BRITISH COLUMBIA AND THE BC CHILDREN'S HOSPITAL RESEARCH INSTITUTE.</p>
                            <div className="footerImgArea" id="UBC">
                                <div className="image3">
                                    <a href='https://www.ubc.ca' target='_blank'><img alt='image' src={UBC}/></a>
                                    <a href='https://openheartsmap.bcchr.ca/ords/f?p=211:LOGIN_DESKTOP' target='_blank'><img alt='image' src={asset}/></a>
                                    <a href='http://www.bcchildrens.ca' target='_blank'><img alt='image' src={BC}/></a>
                                </div>
                            </div>
                        </div>
                        <div className="footerSection">
                            <p className="footerText">WITH SUPPORT FROM</p>
                            <div className="footerImgArea" id="AEA">
                                <div className="image2">
                                    <div className="image2in1">
                                        <a href='https://www.ualberta.ca/pediatrics/pediatric-research/affiliated-research-units/alberta-research-centre-for-health-evidence-arche/index.html' target='_blank'><img alt='image' src={arche}/></a>
                                        <a href='http://www.echokt.ca' target='_blank'><img alt='image' src={echo}/></a>
                                    </div>
                                    <a href='https://www.antigravitydesignco.com' target='_blank'><img alt='image' src={anti}/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
        </>
    )
}

export default Welcome
